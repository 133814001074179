
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
export default defineComponent({
  props: {},
  setup() {
    const router = useRouter();
    const state = reactive<{
      postList: any;
      currentPage: any;
      pageSize: any;
      totalPost: any;
      isLoading: boolean;
      isInit: boolean;
    }>({
      postList: [],
      currentPage: 1,
      pageSize: 20,
      totalPost: 0,
      isLoading: true,
      isInit: false,
    });

    onMounted(() => {
      searchPost();
    });
    function searchPost() {
      state.isLoading = true;
      state.isInit = false;
      recruitService.user
        .custSignPage({
          pageIndex: state.currentPage,
          pageSize: state.pageSize,
        })
        .then((val) => {
          state.postList = val.data.records;
          state.totalPost = val.data.total;
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
      state.isLoading = false;
      state.isInit = true;
    }
    function handleSizeChange(val: number) {
      state.pageSize = val;
      searchPost();
    }
    function handleCurrentChange(val: number) {
      state.currentPage = val;
      searchPost();
    }
    function toPostList() {
      router.push(`/postList`).catch((err) => {
        console.warn(err);
      });
    }
    function toDetail(item: any) {
      router.push(`/postDetail?postId=${item.postId}`).catch((err) => {
        console.warn(err);
      });
    }
    return {
      ...toRefs(state),
      searchPost,
      handleSizeChange,
      handleCurrentChange,
      toDetail,
      toPostList,
      Search,
    };
  },
});
